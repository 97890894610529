import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import stepPathTop from 'src/assets/images/landing/step-path-top.svg'
import stepPathMobileLeft from 'src/assets/images/landing/step-path-mobile-left.svg'
import stepPathMobileRight from 'src/assets/images/landing/step-path-mobile-right.svg'
import stepPathBottom from 'src/assets/images/landing/step-path-bottom.svg'

import stepBackground1 from 'src/assets/images/landing/Bg-panda1.svg'
import stepIllustration1 from 'src/assets/images/landing/Panda1.svg'

import stepBackground2 from 'src/assets/images/landing/Bg-panda2.svg'
import stepIllustration2 from 'src/assets/images/landing/Panda2.svg'

import stepBackground3 from 'src/assets/images/landing/Bg-panda3.svg'
import stepIllustration3 from 'src/assets/images/landing/Panda3.svg'

import stepBackground4 from 'src/assets/images/landing/Bg-panda4.svg'
import stepIllustration4 from 'src/assets/images/landing/Panda4.svg'

import DeviceDetector from 'src/components/DeviceDetector'
import Container from 'src/components/Container'

export const HowItWorksSection = (props) => {
  const { t } = useTranslation()

  const StepsWeb = (
    <div className='how-it-works__steps-container'>
      {/* STEP 1 */}
      <div className='how-it-works__step'>
        <div className='how-it-works__step-image how-it-works__step-image--1'>
          <img src={stepPathBottom} className='how-it-works__step-image-path-bottom' alt='step-1-path' />
          <img src={stepBackground1} className='how-it-works__step-image-background' alt='step-1-background' />
          <div className='how-it-works__step-image-illustration-container'>
            <img src={stepIllustration1} className='how-it-works__step-image-illustration how-it-works__step-image-illustration--1' alt='step-1-illustration' />
          </div>
        </div>
        <div className='how-it-works__step-text'>
          <div className='how-it-works__step-title'>
            1. {t('landingPage.howItWorksSection.step1')}
          </div>
          <div className='how-it-works__step-description web'>
            {t('landingPage.howItWorksSection.step1description')}
          </div>
        </div>
      </div>
      {/* STEP 2 */}
      <div className='how-it-works__step'>
        <div className='how-it-works__step-image how-it-works__step-image--2'>
          <img src={stepPathTop} className='how-it-works__step-image-path-top' alt='step-2-path' />
          <img src={stepBackground2} className='how-it-works__step-image-background' alt='step-2-background' />
          <div className='how-it-works__step-image-illustration-container'>
            <img src={stepIllustration2} className='how-it-works__step-image-illustration how-it-works__step-image-illustration--2' alt='step-2-illustration' />
          </div>
        </div>
        <div className='how-it-works__step-text'>
          <div className='how-it-works__step-title'>
            2. {t('landingPage.howItWorksSection.step2')}
          </div>
          <div className='how-it-works__step-description web'>
            {t('landingPage.howItWorksSection.step2description')}
          </div>
        </div>
      </div>
      {/* STEP 3 */}
      <div className='how-it-works__step'>
        <div className='how-it-works__step-image how-it-works__step-image--3'>
          <img src={stepPathBottom} className='how-it-works__step-image-path-bottom' alt='step-3-path' />
          <img src={stepBackground3} className='how-it-works__step-image-background' alt='step-3-background' />
          <div className='how-it-works__step-image-illustration-container'>
            <img src={stepIllustration3} className='how-it-works__step-image-illustration how-it-works__step-image-illustration--3' alt='step-3-illustration' />
          </div>
        </div>
        <div className='how-it-works__step-text'>
          <div className='how-it-works__step-title'>
            3. {t('landingPage.howItWorksSection.step3')}
          </div>
          <div className='how-it-works__step-description web'>
            {t('landingPage.howItWorksSection.step3description')}
          </div>
        </div>
      </div>
      {/* STEP 2 */}
      <div className='how-it-works__step'>
        <div className='how-it-works__step-image how-it-works__step-image--4'>
          <img src={stepBackground4} className='how-it-works__step-image-background' alt='step-4-background' />
          <div className='how-it-works__step-image-illustration-container'>
            <img src={stepIllustration4} className='how-it-works__step-image-illustration how-it-works__step-image-illustration--4' alt='step-4-illustration' />
          </div>
        </div>
        <div className='how-it-works__step-text'>
          <div className='how-it-works__step-title'>
            4. {t('landingPage.howItWorksSection.step4')}
          </div>
          <div className='how-it-works__step-description web'>
            {t('landingPage.howItWorksSection.step4description')}
          </div>
        </div>
      </div>
    </div>
  )

  const StepsMobile = (
    <div className='how-it-works-mobile__steps-container'>
      {/* MOBILE STEP 1 */}
      <div className='how-it-works-mobile__step'>
        <div className='how-it-works-mobile__step-image how-it-works-mobile__step-image--left'>
          <img src={stepPathMobileRight} className='how-it-works-mobile__step-image-path-right' alt='step-1-path' />
          <img src={stepBackground1} className='how-it-works-mobile__step-image-background' alt='step-1-background' />
          <div className='how-it-works-mobile__step-image-illustration-container'>
            <img src={stepIllustration1} className='how-it-works-mobile__step-image-illustration how-it-works-mobile__step-image-illustration--1' alt='step-1-illustration' />
          </div>
        </div>
        <div className='how-it-works-mobile__step-title'>
          1. {t('landingPage.howItWorksSection.step1')}
        </div>
      </div>
      {/* MOBILE STEP 2 */}
      <div className='how-it-works-mobile__step'>
        <div className='how-it-works-mobile__step-title'>
          2. {t('landingPage.howItWorksSection.step2')}
        </div>
        <div className='how-it-works-mobile__step-image how-it-works-mobile__step-image--right'>
          <img src={stepPathMobileLeft} className='how-it-works-mobile__step-image-path-left' alt='step-2-path' />
          <img src={stepBackground2} className='how-it-works-mobile__step-image-background' alt='step-2-background' />
          <div className='how-it-works-mobile__step-image-illustration-container'>
            <img src={stepIllustration2} className='how-it-works-mobile__step-image-illustration how-it-works-mobile__step-image-illustration--2' alt='step-2-illustration' />
          </div>
        </div>
      </div>
      {/* MOBILE STEP 3 */}
      <div className='how-it-works-mobile__step'>
        <div className='how-it-works-mobile__step-image how-it-works-mobile__step-image--left'>
          <img src={stepPathMobileRight} className='how-it-works-mobile__step-image-path-right' alt='step-3-path' />
          <img src={stepBackground3} className='how-it-works-mobile__step-image-background' alt='step-3-background' />
          <div className='how-it-works-mobile__step-image-illustration-container'>
            <img src={stepIllustration3} className='how-it-works-mobile__step-image-illustration how-it-works-mobile__step-image-illustration--3' alt='step-3-illustration' />
          </div>
        </div>
        <div className='how-it-works-mobile__step-title'>
          3. {t('landingPage.howItWorksSection.step3')}
        </div>
      </div>
      {/* MOBILE STEP 4 */}
      <div className='how-it-works-mobile__step'>
        <div className='how-it-works-mobile__step-title'>
          4. {t('landingPage.howItWorksSection.step4')}
        </div>
        <div className='how-it-works-mobile__step-image how-it-works-mobile__step-image--right'>
          <img src={stepBackground4} className='how-it-works-mobile__step-image-background' alt='step-4-background' />
          <div className='how-it-works-mobile__step-image-illustration-container'>
            <img src={stepIllustration4} className='how-it-works-mobile__step-image-illustration how-it-works-mobile__step-image-illustration--4' alt='step-4-illustration' />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <DeviceDetector>
      <StyledContainer>
        <HowItWorksSectionContainer>
          <div className='how-it-works__title'>
            {t('landingPage.howItWorksSection.title')}
          </div>
          <div className='how-it-works__subtitle'>
            {t('landingPage.howItWorksSection.subtitle')}
          </div>
          <div className='large-desktop'>
            {StepsWeb}
          </div>
          <div className='small-desktop'>
            {StepsMobile}
          </div>
          <div className='mobile'>
            {StepsMobile}
          </div>
        </HowItWorksSectionContainer>
      </StyledContainer>
    </DeviceDetector>
  )
}

export default HowItWorksSection

const StyledContainer = styled(Container)`
  ${props => props.theme.breakpointsMedia.mobile} {
    overflow: hidden;
  }
`

const HowItWorksSectionContainer = styled.div`
  color: ${props => props.theme.palette.common.black};
  text-align: center;
  margin-top: 200px;
  margin-bottom: 205px;
  
  ${props => props.theme.breakpointsMedia.smallDesktop} {
    margin-top: 41px;
    margin-bottom: 60px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: 41px;
    margin-bottom: 60px;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (max-width: 360px) {
    overflow: hidden;
  }

  .how-it-works__title {
    font-size: 52px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 18px;
      padding: 0 10px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  
  .how-it-works__subtitle {
    font-size: 26px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 13px;
      padding: 0 10px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 13px;
      padding: 0 10px;
    }
  }
  
  .how-it-works__steps-container {
    display: flex;
    justify-content: center;
    margin-top: 82px;
  }
  
  .how-it-works__step {
    width: 235.5px;
    margin: 0 25px;
  }
  
  .how-it-works__step-image {
    display: inline-block;
    position: relative;
  }
  
  .how-it-works__step-image--1 {
    width: 235.5px;
    height: 217.5px;
  }
  
  .how-it-works__step-image--2 {
    width: 219.75px;
    height: 214.5px;
  }
  
  .how-it-works__step-image--3 {
    width: 228.75px;
    height: 217.5px;
  }
  
  .how-it-works__step-image--4 {
    width: 229.5px;
    height: 216px;
  }
  
  .how-it-works__step-image-path-bottom {
    position: absolute;
    bottom: 0;
    right: -30%;
  }
  
  .how-it-works__step-image-path-top {
    position: absolute;
    width: 170px;
    top: 0;
    right: -35%;
  }
  
  .how-it-works__step-image-background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .how-it-works__step-image-illustration-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .how-it-works__step-image-illustration {
    box-sizing: border-box;
    position: absolute;
    bottom: 25px;
    width: 75%;
  }
  
  .how-it-works__step-image-illustration--1 {
    left: 20px;
  }
  
  .how-it-works__step-image-illustration--2 {
    left: 0;
  }
  
  .how-it-works__step-image-illustration--3 {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 55%;
  }
  
  .how-it-works__step-image-illustration--4 {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 40px;
    width: 55%;
  }
  
  .how-it-works__step-text {
    text-align: center;
    margin-top: 51px;
  }
  
  .how-it-works__step-title {
    font-size: 17px;
    font-weight: 600;
    min-height: 65px;
  }
  
  .how-it-works__step-description {
    font-size: 13px;
    margin-top: 10px;
  }
  
  .how-it-works-mobile__steps-container {
    width: 100%;
    margin-top: 29px;
  }
  
  .how-it-works-mobile__step {
    display: flex;
    align-items: center;
  }
  
  .how-it-works-mobile__step-image {
    display: inline-block;
    position: relative;
    min-height: 135px;
    width: 135px;
    flex: none;
  }
  
  .how-it-works-mobile__step-image-path-right {
    position: absolute;
    bottom: -20%;
    right: -80%;
    width: 115px;
  }
  
  .how-it-works-mobile__step-image-path-left {
    position: absolute;
    bottom: -30%;
    left: -80%;
    width: 115px;
  }
  
  .how-it-works-mobile__step-image--left {
    left: -10px;
  }
  
  .how-it-works-mobile__step-image--right {
    right: -10px;
  }
  
  .how-it-works-mobile__step-image-background {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; 
  }
  
  .how-it-works-mobile__step-image-illustration-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; 
  }
  
  .how-it-works-mobile__step-image-illustration {
    position: absolute;
    bottom: 20px;
    width: 70%;
  }
  
  .how-it-works-mobile__step-image-illustration--1 {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  .how-it-works-mobile__step-image-illustration--2 {
    left: 0;
    width: 80%;
  }
  
  .how-it-works-mobile__step-image-illustration--3 {
    left: 0;
    right: 0;
    width: 60%;
    margin: 0 auto;
    bottom: 25px;
  }
  
  .how-it-works-mobile__step-image-illustration--4 {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 60%;
    bottom: 35px;
  }
  
  .how-it-works-mobile__step-title {
    font-size: 16px;
    font-weight: 600;
    max-width: 219px;
    padding: 0 25px;
  }
`
