import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Container from 'src/components/Container'

import bubbleYellow from 'src/assets/images/vectors/bubble-yellow.svg'
import bubbleOrange from 'src/assets/images/vectors/bubble-orange.svg'
import bubblePurple from 'src/assets/images/vectors/bubble-purple.svg'
import bubbleTurquise from 'src/assets/images/vectors/bubble-turquise.svg'

import photo1 from 'src/assets/images/landing/zdjęcia1.svg'
import photo2 from 'src/assets/images/landing/zdjęcia2.svg'
import photo3 from 'src/assets/images/landing/zdjęcia3.svg'
import photo4 from 'src/assets/images/landing/zdjęcia4.svg'
import photo5 from 'src/assets/images/landing/zdjęcia11.svg'
import photo6 from 'src/assets/images/landing/zdjęcia12.svg'
import photo7 from 'src/assets/images/landing/zdjęcia13.svg'
import photo8 from 'src/assets/images/landing/zdjęcia14.svg'
import photo9 from 'src/assets/images/landing/zdjęcia15.svg'
import photo10 from 'src/assets/images/landing/zdjęcia16.svg'
import photo11 from 'src/assets/images/landing/zdjęcia17.svg'

export const AboutSection = (props) => {
  const { t } = useTranslation()

  return (
    <AboutSectionContainer>
      <Container>
        <div className='about-section__title'>
          {t('landingPage.aboutSection.title')}
        </div>
        <div className='about-section__paragraph about-section__paragraph--1'>
          {t('landingPage.aboutSection.paragraph1')}
        </div>
        <Trans parent='div' i18nKey='landingPage.aboutSection.paragraph2' className='about-section__paragraph about-section__paragraph--2'>
          <strong>0</strong>1
        </Trans>
        <Trans parent='div' i18nKey='landingPage.aboutSection.paragraph3' className='about-section__paragraph about-section__paragraph--2'>
          0<strong>1</strong>
        </Trans>
        <div className='about-section__paragraph about-section__paragraph--4'>
          {t('landingPage.aboutSection.paragraph4')}
        </div>
      </Container>
      <img src={bubbleYellow} className='about-section__bubble-yellow' alt='about-section-bubble-yellow' />
      <img src={bubbleOrange} className='about-section__bubble-orange' alt='about-section-bubble-orange' />
      <img src={bubblePurple} className='about-section__bubble-purple' alt='about-section-bubble-purple' />
      <img src={bubbleTurquise} className='about-section__bubble-turquise' alt='about-section-bubble-turquise' />

      <div className='about-section__shadow-yellow' />
      <div className='about-section__shadow-orange' />
      <div className='about-section__shadow-purple' />
      <div className='about-section__shadow-turquise' />

      <img src={photo1} className='about-section__photo1' alt='about-section-photo1' />
      <img src={photo2} className='about-section__photo2' alt='about-section-photo2' />
      <img src={photo3} className='about-section__photo3' alt='about-section-photo3' />
      <img src={photo4} className='about-section__photo4' alt='about-section-photo4' />
      <img src={photo5} className='about-section__photo5' alt='about-section-photo5' />
      <img src={photo6} className='about-section__photo6' alt='about-section-photo6' />
      <img src={photo7} className='about-section__photo7' alt='about-section-photo7' />
      <img src={photo8} className='about-section__photo8' alt='about-section-photo8' />
      <img src={photo9} className='about-section__photo9' alt='about-section-photo9' />
      <img src={photo10} className='about-section__photo10' alt='about-section-photo10' />
      <img src={photo11} className='about-section__photo11' alt='about-section-photo11' />
    </AboutSectionContainer>
  )
}

export default AboutSection

const AboutSectionContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 93px;
  margin-bottom: 442px;
  color: ${props => props.theme.palette.common.black};
  
  ${props => props.theme.breakpointsMedia.mobile} {
    margin-top: 35px;
    margin-bottom: 115px;
  }
    
  ${props => props.theme.breakpointsMedia.smallDesktop} {
    margin-top: 35px;
    margin-bottom: 115px;
  }
  
  .about-section__title {
    box-sizing: border-box;
    text-align: center;
    max-width: 1006px;
    margin: 0 auto;
    line-height: 1.1;
    letter-spacing: -0.03px;
    font-size: 52.5px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      line-height: 22.5px;
      letter-spacing: -0.01px;
      font-size: 18.75px;
      padding: 0 10px;
    }
    
  ${props => props.theme.breakpointsMedia.smallDesktop} {
      line-height: 22.5px;
      letter-spacing: -0.01px;
      font-size: 18.75px;
      padding: 0 10px;
    }
  }
  
  .about-section__paragraph {
    z-index: 3;
    box-sizing: border-box;
    max-width: 1006px;
    color: ${props => props.theme.palette.common.black};
    margin-top: 43px;
    text-align: center;
    letter-spacing: -0.03px;
    font-size: 22.5px;
    margin-left: auto;
    margin-right: auto;
    
    @media (min-width: 1024px) and (max-width: 1440px) {
      max-width: 700px;
    }
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin-top: 20px;
      letter-spacing: -0.02px;
      font-size: 12px;
      padding: 0 10px;
      text-align: left;
      max-width: 550px;
    }
    
    @media (min-width: 425px) and (max-width: 768px) {
      margin-top: 20px;
      letter-spacing: -0.02px;
      font-size: 12px;
      padding: 0 20px;
      text-align: left;
      max-width: 400px;
    }
    
    @media (max-width: 425px) {
      margin-top: 20px;
      letter-spacing: -0.02px;
      font-size: 12px;
      padding: 0 20px;
      text-align: left;
      max-width: 250px;
    }
    
    @media (max-width: 320px) {
      max-width: 170px;
    }
  }
  
  .about-section__paragraph--1,
  .about-section__paragraph--2 {
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin-left: auto;
      margin-right: 0;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-left: auto;
      margin-right: 0;
    }
  }
  
  .about-section__paragraph--3,
  .about-section__paragraph--4 {
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin-left: 0;
      margin-right: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-left: 0;
      margin-right: auto;
    }
  }
  
  .about-section__paragraph--3 {
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin-top: 138px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin-top: 138px;
    }
  }
  
  .about-section__photo1 {
    position: absolute;
    top: 5%;
    left: 0;
    width: 94.5px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 52.5px;
      top: 10%;
      left: 1%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 52.5px;
      top: 10%;
      left: 1%;
    }
  }
  
  .about-section__bubble-orange {
    position: absolute;
    width: 29.25px;
    height: 27,75px;
    top: 20%;
    left: 10%;
    z-index: 2;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 16.5px;
      top: 16%;
      left: 25%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 16.5px;
      top: 16%;
      left: 25%;
    }
  }
  
  .about-section__shadow-orange {
    display: inline-block;
    position: absolute;
    top: 25%;
    left: 12%;  
    z-index: 1;
    border-radius: 50%;
    box-shadow: 10px 10px 30px 15px ${props => props.theme.palette.primary.main};
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: none;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
  .about-section__photo2 {
    position: absolute;
    top: 40%;
    left: 10%;
    width: 139.5px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      top: 23%;
      left: 10%;
      width: 77.25px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      top: 23%;
      left: 10%;
      width: 77.25px;
    }
  }
  
  .about-section__photo3 {
    position: absolute;
    top: 75%;
    left: 1%; 
    width: 163.5px; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      top: 37%;
      left: 1%;
      width: 78px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      top: 37%;
      left: 1%;
      width: 78px;
    }
  }
  
  .about-section__bubble-purple {
    position: absolute;
    width: 36px;
    bottom: -20%;
    left: 2%;  
    z-index: 2;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      top: 40%;
      bottom: auto;
      left: 32%;
      width: 19.5px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      top: 40%;
      bottom: auto;
      left: 32%;
      width: 19.5px;
    }
  }
  
  .about-section__shadow-purple {
    display: inline-block;
    position: absolute;
    bottom: -20%;
    left: 3.5%;    
    z-index: 1;
    border-radius: 50%;
    box-shadow: 10px 10px 30px 15px ${props => props.theme.palette.secondary.main}; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      top: 43%;
      left: 38%;
      bottom: auto;
      box-shadow: 5px 5px 20px 10px ${props => props.theme.palette.secondary.main}; 
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      top: 43%;
      left: 38%;
      bottom: auto;
      box-shadow: 5px 5px 20px 10px ${props => props.theme.palette.secondary.main}; 
    }
  }
  
  .about-section__photo4 {
    position: absolute;
    bottom: -40%;
    left: 13%; 
    width: 103.5px;  
    
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 69.75px;
      right: auto;
      top: 50%;
      left: 20%;
      bottom: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 69.75px;
      right: auto;
      top: 50%;
      left: 20%;
      bottom: auto;
    }
  }
  
  .about-section__photo5 {
    position: absolute;
    top: 0;
    right: 10%;  
    width: 86.25px; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 77.25px;
      right: 2%;
      top: 55%;
      left: auto;
      bottom: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 77.25px;
      right: 2%;
      top: 55%;
      left: auto;
      bottom: auto;
    }
  }
 
  .about-section__bubble-yellow {
    z-index: 2;
    position: absolute;
    width: 47.25px;
    top: 15%;
    right: 5%; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 26.25px;
      right: 40%;
      top: 55%;
      left: auto;
      bottom: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 26.25px;
      right: 40%;
      top: 55%;
      left: auto;
      bottom: auto;
    }
  }
  
  .about-section__shadow-yellow {
    display: inline-block;
    position: absolute;
    top: 20%;
    right: 5%;    
    z-index: 1;
    border-radius: 50%;
    box-shadow: 10px 10px 30px 15px ${props => props.theme.palette.warning.main}; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      right: 50%;
      top: 60%;
      left: auto;
      bottom: auto;
      box-shadow: 5px 5px 20px 10px ${props => props.theme.palette.warning.main}; 
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      right: 50%;
      top: 60%;
      left: auto;
      bottom: auto;
      box-shadow: 5px 5px 20px 10px ${props => props.theme.palette.warning.main}; 
    }
  }
  
  .about-section__photo6 {
    position: absolute;
    top: 40%;
    right: 10%;  
    width: 147.75px;  
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 66.75px;  
      right: 21%;
      top: 69%;
      left: auto;
      bottom: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 66.75px;  
      right: 21%;
      top: 69%;
      left: auto;
      bottom: auto;
    }
  }
  
  .about-section__photo7 {
    position: absolute;
    top: 75%;
    right: 0;  
    width: 135px;  
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 67.5px;  
      right: 1%;
      top: 83%;
      left: auto;
      bottom: auto;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 67.5px;  
      right: 1%;
      top: 83%;
      left: auto;
      bottom: auto;
    }
  }
  
  
  .about-section__photo8 {
    position: absolute;
    bottom: -40%;
    right: 13%; 
    width: 86.25px; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 57.75px;  
      right: 13%;
      top: auto;
      left: auto;
      bottom: -10%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 57.75px;  
      right: 13%;
      top: auto;
      left: auto;
      bottom: -10%;
    }
  }

  .about-section__photo9 {
    position: absolute;
    bottom: -30%;
    right: 30%;
    width: 94.5px;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 57.75px;
      left: 3%;
      top: auto;
      right: auto;
      bottom: -10%;
    }

    ${props => props.theme.breakpointsMedia.mobile} {
      width: 45.75px;
      left: 9%;
      top: auto;
      right: auto;
      bottom: -15%;
    }
  }

  .about-section__photo10 {
    position: absolute;
    bottom: -25%;
    left: 30%;
    width: 66.25px;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 37.75px;
      right: 20%;
      top: auto;
      left: auto;
      bottom: -25%;
    }

    ${props => props.theme.breakpointsMedia.mobile} {
      width: 37.75px;
      left: 28%;
      top: auto;
      right: auto;
      bottom: -10%;
    }
  }

  .about-section__photo11 {
    position: absolute;
    bottom: -55%;
    left: 45%;
    width: 96.25px;

    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 37.75px;
      left: 13%;
      top: auto;
      right: auto;
      bottom: -30%;
    }

    ${props => props.theme.breakpointsMedia.mobile} {
      width: 47.75px;
      left: 45%;
      top: auto;
      right: auto;
      bottom: -15%;
    }
  }
  
  
  .about-section__bubble-turquise {
    width: 47.25px;
    position: absolute;
    bottom: -12%;
    right: 13%; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 22.5px;  
      right: 32%;
      top: auto;
      left: auto;
      bottom: 2%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 22.5px;  
      right: 32%;
      top: auto;
      left: auto;
      bottom: 2%;
    }
  }
  
  .about-section__shadow-turquise {
    display: inline-block;
    position: absolute;
    bottom: -11%;
    right: 13%;    
    z-index: 1;
    border-radius: 50%;
    box-shadow: 10px 10px 30px 15px ${props => props.theme.palette.tertiary.main}; 
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      display: none;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      display: none;
    }
  }
  
`
