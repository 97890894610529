import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import PATHS from 'src/utils/paths'

export const SignUpButton = (props) => {
  const { t } = useTranslation()
  const navigateToPath = () => navigate(PATHS.signUp)

  return (
    <StyledDemoButton className={props.className} onClick={navigateToPath}>
      {t('navigation.signUp')}
    </StyledDemoButton>
  )
}

export default SignUpButton

const StyledDemoButton = styled.div`
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.common.black};
  text-align: center;
  border-radius: 9px;
  font-size: 13px;
  padding: 5px 10px;
  white-space: nowrap;
  
  ${props => props.theme.breakpointsMedia.mobile} {
    line-height: 16px;
  }
`
