import React from 'react'
import { LANDING_PAGE_SECTIONS } from 'src/utils/paths'

import WelcomeSection from 'src/features/landingPage/components/WelcomeSection'
import HowItWorksSection from 'src/features/landingPage/components/HowItWorksSection'
import BenefitsSection from 'src/features/landingPage/components/BenefitsSection'
import AboutSection from 'src/features/landingPage/components/AboutSection'
// import DemoSection from 'src/features/landingPage/components/DemoSection'
// import SignUpSection from 'src/features/landingPage/components/SignUpSection'

export const LandingPage = props => {
  return (
    <>
      <WelcomeSection />
      <div id={LANDING_PAGE_SECTIONS.howItWorksSection} />
      <HowItWorksSection />
      <div id={LANDING_PAGE_SECTIONS.benefitsSection} />
      <BenefitsSection />
      <div id={LANDING_PAGE_SECTIONS.aboutSection} />
      <AboutSection />
      {/* <div id={LANDING_PAGE_SECTIONS.demoSection} /> */}
      {/* <DemoSection /> */}
      {/* <SignUpSection /> */}
      <div id={LANDING_PAGE_SECTIONS.contactSection} />
    </>
  )
}

export default LandingPage
