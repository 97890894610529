import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Container from 'src/components/Container'
import Grid from '@material-ui/core/Grid'

import background from 'src/assets/images/landing/bg.svg'
import backgroundMobile from 'src/assets/images/landing/bg-mobile.png'
import bubbleYellow from 'src/assets/images/landing/bg-bubble-yellow.svg'
import bubbleTurquise from 'src/assets/images/landing/bg-bubble-turquise.svg'
import bubblePurple from 'src/assets/images/landing/bg-bubble-purple.svg'
import welcomeImage from 'src/assets/images/landing/welcome-image-compressed.png'
import {
  round2,
  round3,
  round4
} from 'src/components/AnimationKeyframes'
import DeviceDetector from 'src/components/DeviceDetector'
// import DemoButton from 'src/features/landingPage/components/DemoButton'
import SignUpButton from 'src/features/landingPage/components/SignupButton'

export const WelcomeSection = props => {
  const { t } = useTranslation()

  return (
    <DeviceDetector>
      <MainSectionContainer>
        <BackgroundImagePlaceholder className='web' src={background} alt='welcome-section-background-image' />
        <BackgroundImagePlaceholder className='mobile' src={backgroundMobile} alt='welcome-section-background-image' />
        <BackgroundImage className='web' src={background} alt='welcome-section-background-image' />
        <BackgroundImage className='mobile' src={backgroundMobile} alt='welcome-section-background-image' />
        <MainSectionContent>
          <Container>
            <div className='welcome-section-content'>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div>
                    <div className='welcome-text-1'>
                      {t('landingPage.welcomeSection.welcomeText1')}
                    </div>
                    <div className='welcome-text-2'>
                      {t('landingPage.welcomeSection.welcomeText2')}
                    </div>
                    <div className='welcome-text-3'>
                      {t('landingPage.welcomeSection.welcomeText3')}
                    </div>
                    <div className='welcome-text-4'>
                      {t('landingPage.welcomeSection.welcomeText4')}
                    </div>
                    <DemoButtonContainer>
                      {/* <DemoButton className='mobile-inline' /> */}
                      <SignUpButton className='mobile-inline' />
                    </DemoButtonContainer>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className='welcome-section-image'>
                    <img className='welcome-section__image web' src={welcomeImage} alt='welcome-image' />
                    <img className='welcome-section__bubble-yellow' src={bubbleYellow} alt='bubble-1' />
                    <img className='welcome-section__bubble-turquise' src={bubbleTurquise} alt='bubble-2' />
                    <img className='welcome-section__bubble-purple' src={bubblePurple} alt='bubble-3' />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </MainSectionContent>
      </MainSectionContainer>
    </DeviceDetector>
  )
}

export default WelcomeSection

const MainSectionContainer = styled.div`
  margin-top: -${props => props.theme.dimensions.navHeight};
  position: relative;
  box-sizing: border-box;
  width: 100%;
`

const BackgroundImagePlaceholder = styled.img`
  visibility: hidden;
  width: 100%;
`

const DemoButtonContainer = styled.div`
  margin-top: 16px;
`

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const MainSectionContent = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: ${props => props.theme.dimensions.navHeight};
  color: ${props => props.theme.palette.common.white};
  
 ${props => props.theme.breakpointsMedia.mobile} {
    padding: 0 5px;
    margin-top: calc(${props => props.theme.dimensions.navHeight} - 25px);
  }
  
  .welcome-section-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 425px) {
      padding: 0 17px;
    }
    
    @media (min-width: 425px) and (max-width: 768px) {
      padding: 0 30px;
    }
  }
  
  .welcome-text-1 {
    margin-top: 151px;
    font-size: 30px;
    font-weight: 300;
  }
  .welcome-text-2 {
    margin-top: 26px;
  }
  
  .welcome-text-2,
  .welcome-text-3,
  .welcome-text-4 {
    font-size: 53px;
    line-height: 63px;
  }
  
  .welcome-section-image {
    position: relative;
    width: 100%;
    max-width: 565px;
    
     ${props => props.theme.breakpointsMedia.smallDesktop} {
        height: 100%;
        width: auto;
        position: static;
     }
     
     ${props => props.theme.breakpointsMedia.mobile} {
        height: auto;
        width: auto;
        position: static;
     }
  }
  
  .welcome-section__image {
     margin-top: 60px;
     max-width: 565px;
     
     ${props => props.theme.breakpointsMedia.smallDesktop} {
        display: none;
     }
  }
  
  .welcome-section__bubble-yellow,
  .welcome-section__bubble-purple {
    max-width: 55px;
  }
  
  .welcome-section__bubble-yellow,
  .welcome-section__bubble-turquise,
  .welcome-section__bubble-purple {
    position: absolute;  
  }
  
  .welcome-section__bubble-turquise {
    max-width: 47px;
    top: 50%;
    right: -30%;
    animation: ${round2} 7s ease-in-out infinite;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 22px;
      top: 50%;
      right: 15%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 22px;
      top: 50%;
      right: 15%;
    }
  }
  
  .welcome-section__bubble-yellow {
    max-width: 47px;
    top: 10%;
    right: -15%;
    animation: ${round3} 8s ease-in-out infinite;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 22px;
      top: 10%;
      right: 25%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 22px;
      top: 10%;
      right: 25%;
    }
  }
  
  .welcome-section__bubble-purple {
    max-width: 47px;
    bottom: 10%;
    right: -5%;
    animation: ${round4} 9s ease-in-out infinite;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 22px;
      right: 30%;
      bottom: 5%;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 22px;
      right: 30%;
      bottom: 5%;
    }
  }
  
  ${props => props.theme.breakpointsMedia.mobile} {
    .welcome-text-1 {
      margin-top: 30px;
      font-size: 19px;
      font-weight: 300;
    }
    .welcome-text-2 {
      margin-top: 10px;
    }
    
    .welcome-text-2,
    .welcome-text-3,
    .welcome-text-4 {
      font-size: 23px;
      line-height: 28px;
      letter-spacing: -0.4px;
    }
  }
  
  ${props => props.theme.breakpointsMedia.smallDesktop} {
    .welcome-text-1 {
      margin-top: 30px;
      font-size: 25px;
      font-weight: 300;
    }
    .welcome-text-2 {
      margin-top: 30px;
    }
    
    .welcome-text-4 {
      margin-bottom: 20px;
    }
    
    .welcome-text-2,
    .welcome-text-3,
    .welcome-text-4 {
      font-size: 35px;
      line-height: 41px;
      letter-spacing: -0.4px;
    }
  }
`
