import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'
import PATHS from 'src/utils/paths'
import { getIsAuthorised } from 'src/features/auth/duck/selectors'

const withPrivatePage = BaseComponent => props => {
  const isAuthorised = useSelector(getIsAuthorised)

  useEffect(() => {
    isAuthorised && navigate(PATHS.workspaceDashboard)
  }, [])

  return <BaseComponent {...props} />
}

export default withPrivatePage
