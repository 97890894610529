import { keyframes } from 'styled-components'

export const floating1 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const floating2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const floating3 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const floating4 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
`

export const primaryBlurredBoxShadow = keyframes`
  0% {
    border-color: transparent;
    box-shadow: 4px 0 20px -3px #8653d03b;
  }
  
  25% {
    border-color: #8653d03b;
    box-shadow: 4px 0 20px 0px #8653d03b;
  }
  
  50% {
    border-color: transparent;
    box-shadow: 4px 0 20px 3px #8653d03b;
  }
  
  75% {
    border-color: #8653d03b;
    box-shadow: 4px 0 20px 0px #8653d03b;
  }
  
  100% {
    border-color: transparent;
    box-shadow: 4px 0 20px -3px #8653d03b;
  }
`

export const round1 = keyframes`
  0%{
    transform:rotate(0deg)
              translate(-5px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-5px)
              rotate(-360deg);
  }
`

export const round2 = keyframes`
  0%{
     transform:rotate(360deg)
              translate(-5px)
              rotate(-360deg);
    
  
  }
  100%{
    transform:rotate(0deg)
              translate(-5px)
              rotate(0deg);
  }
`

export const round3 = keyframes`
  0%{
    transform:rotate(0deg)
              translate(-5px)
              rotate(0deg);
  
  }
  100%{
    transform:rotate(360deg)
              translate(-5px)
              rotate(-360deg);
  }
`

export const round4 = keyframes`
  0%{
     transform:rotate(360deg)
              translate(-5px)
              rotate(-360deg);
    
  
  }
  100%{
    transform:rotate(0deg)
              translate(-5px)
              rotate(0deg);
  }
`
