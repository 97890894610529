import React from 'react'
import styled from 'styled-components'

import { NonAuthNavigation } from 'src/components/Navigation/Navigation'
import Footer from 'src/components/Footer'
import withAuthorisedUser from 'src/hoc/withAuthorisedUser'

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <NonAuthNavigation />
      <Content>{children}</Content>
      <Footer />
    </LayoutContainer>
  )
}

export default withAuthorisedUser(Layout)

const LayoutContainer = styled.div`
  height: 100%;
  min-height: calc(100vh - ${props => props.theme.dimensions.navHeight});
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  margin-top: ${props => props.theme.dimensions.navHeight};
  background-color: ${props => props.theme.palette.common.white};
`

const Content = styled.div`
  width: 100%;
  flex: 1;
`
