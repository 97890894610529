import React from 'react'
import Metadata from 'src/components/Metadata'
import Layout from 'src/theme/Layout'
import { META } from 'src/utils/seo'

import LandingPage from 'src/features/landingPage/LandingPage'

export default () => {
  return (
    <>
      <Metadata meta={META.landingPage} />
      <Layout>
        <LandingPage />
      </Layout>
    </>
  )
}
