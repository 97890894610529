import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import benefitsWave from 'src/assets/images/landing/benefits-wave.svg'
import benefitBg1 from 'src/assets/images/landing/1.svg'
import benefitBg2 from 'src/assets/images/landing/2.svg'
import benefitBg3 from 'src/assets/images/landing/3.svg'
import benefitBg4 from 'src/assets/images/landing/4.svg'
import DeviceDetector from 'src/components/DeviceDetector'
import Container from 'src/components/Container'

export const BenefitsSection = (props) => {
  const { t } = useTranslation()

  return (
    <DeviceDetector>
      <BenefitsSectionContainer>
        <div className='benefits__content'>
          <StyledContainer>
            <div className='benefits__title'>
              {t('landingPage.benefitsSection.title')}
            </div>
            <div className='benefits__subtitle'>
              {t('landingPage.benefitsSection.subtitle')}
            </div>
            <div className='benefits__items'>
              {/* BENEFIT 1 */}
              <div className='benefits__item-container'>
                <div className='benefits__item'>
                  <img src={benefitBg1} className='benefits__item-bg benefits__item-bg--1' alt='benefits-item-bg-1' />
                  <Trans className='benefits__item-text' parent='div' i18nKey='landingPage.benefitsSection.benefit1'>
                    <div className='benefits__item-text-1--1'>1</div>
                    <div className='benefits__item-text-1--2'>2</div>
                    <div className='benefits__item-text-1--3'>3</div>
                  </Trans>
                </div>
              </div>
              {/* BENEFIT 2 */}
              <div className='benefits__item-container'>
                <div className='benefits__item'>
                  <img src={benefitBg2} className='benefits__item-bg benefits__item-bg--2' alt='benefits-item-bg-2' />
                  <Trans className='benefits__item-text benefits__item-text--dark' parent='div' i18nKey='landingPage.benefitsSection.benefit2'>
                    <div className='benefits__item-text-2--1'>1</div>
                    <div className='benefits__item-text-2--2'>2</div>
                  </Trans>
                </div>
              </div>
              {/* BENEFIT 3 */}
              <div className='benefits__item-container'>
                <div className='benefits__item'>
                  <img src={benefitBg3} className='benefits__item-bg benefits__item-bg--3' alt='benefits-item-bg-3' />
                  <Trans className='benefits__item-text' parent='div' i18nKey='landingPage.benefitsSection.benefit3'>
                    <div className='benefits__item-text-3--1'>1</div>
                    <div className='benefits__item-text-3--2'>2</div>
                    <div className='benefits__item-text-3--3'>3</div>
                  </Trans>
                </div>
              </div>
              {/* BENEFIT 4 */}
              <div className='benefits__item-container'>
                <div className='benefits__item'>
                  <img src={benefitBg4} className='benefits__item-bg benefits__item-bg--4' alt='benefits-item-bg-4' />
                  <Trans className='benefits__item-text' parent='div' i18nKey='landingPage.benefitsSection.benefit4'>
                    <div className='benefits__item-text-4--1'>1</div>
                    <div className='benefits__item-text-4--2'>2</div>
                    <div className='benefits__item-text-4--3'>3</div>
                  </Trans>
                </div>
              </div>
            </div>
          </StyledContainer>
        </div>
        <img className='benefits__bottom-background' src={benefitsWave} alt='benefits-section-bottom-wave'/>
      </BenefitsSectionContainer>
    </DeviceDetector>
  )
}

export default BenefitsSection

const BenefitsSectionContainer = styled.div`
  .benefits__content {
    color: ${props => props.theme.palette.common.black};
    text-align: center;
    background-color ${props => props.theme.palette.common.catskillWhite};
    padding-top: 200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      padding-top: 58px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      padding-top: 58px;
    }
  }
  
  .benefits__title {
    font-size: 52px;
    max-width: 1098px;  
    margin: 0 auto;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 18px;
      padding: 0 10px;
    }
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 18px;
      padding: 0 10px;
    }
  }
  
  .benefits__subtitle {
    margin-top: 20px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    font-size: 26px;
    max-width: 1098px;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 12px;
      padding: 0 10px;
    }
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 12px;
      padding: 0 10px;
    }
  }
  
  .benefits__bottom-background {
    width: 100%;
  }
  
  .benefits__items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    ${props => props.theme.breakpointsMedia.mobile} {
      flex-direction: column;
      width: 100%;
      max-width: 270px;
    }
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      flex-direction: column;
      width: 100%;
      max-width: 270px;
    }
  }
  
  .benefits__item-container {
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      width: 100%;
      text-align: left;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      width: 100%;
      text-align: left;
    }
    
    &:nth-child(even) {
      ${props => props.theme.breakpointsMedia.smallDesktop} {
        text-align: right;
      }
      
      ${props => props.theme.breakpointsMedia.mobile} {
        text-align: right;
      }
    }
  }
  
  .benefits__item {
    position: relative;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      margin: 0;
      display: inline-block;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      margin: 0;
      display: inline-block;
    }
  }
  
  .benefits__item-bg {
    max-width: 217.5px;
  }
  
  .benefits__item-bg--1 {
    max-width: 235.5px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 139.2px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 139.2px;
    }
  }
  
  .benefits__item-bg--2 {
    max-width: 221.25px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 131.4px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 131.4px;
    }
  }
  
  .benefits__item-bg--3 {
      max-width: 228.75px;
      
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 148.2px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 148.2px;
    }
  }
  
  .benefits__item-bg--4 {
      max-width: 229.5px;
      
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      max-width: 153px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      max-width: 153px;
    }
  }
  
  
  
  .benefits__item-text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.palette.common.white};
  }
  
  .benefits__item-text--dark {
    color: ${props => props.theme.palette.common.black};
  }
  
  .benefits__item-text-1--1 {
    font-size: 28px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 19px;
      line-height: 14px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 19px;
      line-height: 14px;
    }
  }
  
  .benefits__item-text-1--2 {
    font-size: 20.25px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 13px;
      line-height: 15px;
    }
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 13px;
      line-height: 15px;
    }
  }
  
  .benefits__item-text-1--3 {
    font-size: 38.25px;
    line-height: 30px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 25px;
      line-height: 20px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 25px;
      line-height: 20px;
    }
  }
  
  .benefits__item-text-2--1 {
    font-size: 27.75px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 20px;
      line-height: 15px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 20px;
      line-height: 15px;
    }
  }
  
  .benefits__item-text-2--2 {
    font-size: 20.25px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 13px;
      line-height: 15px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 13px;
      line-height: 15px;
    }
  }
  
  .benefits__item-text-3--1 {
    font-size: 27px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 15px;
      line-height: 13px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 15px;
      line-height: 13px;
    }
  }
  
  .benefits__item-text-3--2 {
    font-size: 40.5px;
    line-height: 37.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 30px;
      line-height: 28px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 30px;
      line-height: 28px;
    }
  }
  
  .benefits__item-text-3--3 {
    font-size: 24.74px;
    line-height: 22.5px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 16px;
      line-height: 16px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 16px;
      line-height: 16px;
    }
  }
  
  .benefits__item-text-4--1 {
    font-size: 24.75px;
    line-height: 24.75px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 16px;
      line-height: 16px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 16px;
      line-height: 16px;
    }
  }
  
  .benefits__item-text-4--2 {
    font-size: 33px;
    line-height: 26.25px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 20px;
      line-height: 19px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 20px;
      line-height: 19px;
    }
  }
  
  .benefits__item-text-4--3 {
    font-size: 24.75px;
    line-height: 24.75px;
    letter-spacing: -0,03px;
    
    ${props => props.theme.breakpointsMedia.smallDesktop} {
      font-size: 14px;
      line-height: 14px;
    }
    
    ${props => props.theme.breakpointsMedia.mobile} {
      font-size: 14px;
      line-height: 14px;
    }
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
